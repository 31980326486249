html,
body {
	height: 100%;
}

body {
	align-items: center;
	display: flex;
	padding-bottom: 40px;
	padding-top: 40px;
}

#root {
	width: 100%;
}
